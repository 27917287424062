/* body {
    position: relative;
    margin: 0;
    height: 100vh;
    display: grid;
    place-content: center;
  } */
  .cont{
    position: fixed;
    display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      height: 100%;
      width: 100%;
      background:rgba(0, 0, 0, 0.0.5);
      z-index:99999
}
.circle {
  transform-origin: center; 
  transform-box: fill-box;
  transform-origin: center;
  animation: rotate linear infinite;
  animation-duration: 3s;
  border-right: 8px solid #fff;
  border-left: 8px solid #fff;
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

